import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { useFeatureFlag } from '@amalia/frontend/web-data-layers';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';
import { CompanyFeatureFlags } from '@amalia/tenants/companies/types';

const PlanAgreementPreview = lazy(async () => ({
  default: (await import('./PlanAgreementPreview')).PlanAgreementPreview,
}));

export const PlanAgreementPreviewRoutes = memo(function PlanAgreementPreviewRoutes() {
  const { formatMessage } = useIntl();
  const { isFeatureEnabled: isPlanAgreementsFFEnabled } = useFeatureFlag(CompanyFeatureFlags.PLAN_AGREEMENTS);

  return (
    <Layout currentPage="plan-agreements">
      <RootRoutes>
        <Route
          path={routes.PLAN_AGREEMENT_PREVIEW}
          element={
            <AbilityRouteProtector can={() => isPlanAgreementsFFEnabled}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Plan agreement | Preview document' })} />
              <PlanAgreementPreview />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
