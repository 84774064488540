import { lazy, memo } from 'react';
import { Route } from 'react-router-dom';

import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';

const CompaniesEditPage = lazy(async () => ({ default: (await import('./CompaniesEditPage')).CompaniesEditPage }));
const CompaniesPage = lazy(async () => ({ default: (await import('./CompaniesPage')).CompaniesPage }));

export const CompaniesRoutes = memo(function CompaniesRoutes() {
  return (
    <RootRoutes>
      <Route
        path={routes.SUPERADMIN_COMPANY}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
            <AmaliaHead title="Superadmin | Company details" />
            <CompaniesEditPage />
          </AbilityRouteProtector>
        }
      />

      <Route
        path={routes.SUPERADMIN_COMPANIES}
        element={
          <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.SuperAdmin)}>
            <AmaliaHead title="Superadmin | Companies" />
            <CompaniesPage />
          </AbilityRouteProtector>
        }
      />
    </RootRoutes>
  );
});
