import { search as fuzzySearch, sortKind } from 'fast-fuzzy';
import { unionBy } from 'lodash';
import { useMemo } from 'react';

import { AmaliaFunction, type AmaliaFunctionArgument } from '@amalia/amalia-lang/formula/evaluate/shared';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { type FormatsEnum } from '@amalia/data-capture/fields/types';

import { sortTokens } from '../../hooks/use-formula-editor-suggestions/sortTokens';
import { type FormulaEditorToken } from '../../types/formulaEditorToken';

import { SuggestionFormulaParser } from './suggestion-formula-parser';

const searchTokens = (query: string, tokens: FormulaEditorToken[]) => {
  if (!query) {
    return tokens;
  }

  // I want to perform a fuzzy search for tokens when type is not keyword or function. Otherwise, I want to perform a strict search.
  const fuzzySearchResultsWithThresold = fuzzySearch(
    query,
    tokens.filter((t) => ![TokenType.KEYWORD, TokenType.FUNCTION].includes(t.type)),
    {
      threshold: 0.8,
      keySelector: (token) => token.name,
      ignoreCase: true,
      sortBy: sortKind.insertOrder,
      returnMatchData: true,
    },
  );

  // For debug purpose of fuzzy search
  // console.table(fuzzySearchResultsWithThresold);

  const fuzzySearchResult = fuzzySearchResultsWithThresold.map((result) => result.item);

  const strictSearchedTokens = tokens.filter(
    (t) =>
      [TokenType.KEYWORD, TokenType.FUNCTION].includes(t.type) && t.name.toLowerCase().includes(query.toLowerCase()),
  );

  return unionBy(strictSearchedTokens, fuzzySearchResult, 'formula');
};

const isItemValidForTokenTypes = (item: FormulaEditorToken, tokenTypes: TokenType[] = []) =>
  tokenTypes.length ? tokenTypes.includes(item.type) : true;

const isItemValidForValues = (
  item: FormulaEditorToken,
  tokenValues: Partial<Record<TokenType, string[]>> | undefined,
) => (tokenValues?.[item.type] ? tokenValues[item.type]?.includes(item.formula) : true);

const isItemValidForFormat = (item: FormulaEditorToken, formats: FormatsEnum[] = []) =>
  formats.length ? (item.format ? formats.includes(item.format) : false) : true;

const isItemValidForFunctionArgument = (item: FormulaEditorToken, functionArgument: AmaliaFunctionArgument) =>
  isItemValidForTokenTypes(item, functionArgument.validTokenTypes) &&
  isItemValidForValues(item, functionArgument.validTokenValues) &&
  isItemValidForFormat(item, functionArgument.validFormats);

const isItemValidForSelectedFilterMachineName = (item: FormulaEditorToken, selectedFilterMachineName?: string) =>
  [TokenType.FIELD, TokenType.PROPERTY].includes(item.type) && selectedFilterMachineName && item.objectDefinitionName
    ? item.objectDefinitionName === selectedFilterMachineName
    : true;
/**
 * Filter items based on the filters provided.
 */
export const useFilteredItems = (items: FormulaEditorToken[], formula: string, query: string) =>
  useMemo(() => {
    const tokensFilteredByQuerySearch = sortTokens(searchTokens(query, items));

    if (!formula) {
      return tokensFilteredByQuerySearch;
    }

    const parserResult = new SuggestionFormulaParser(formula, items).parse();
    if (!parserResult) {
      return tokensFilteredByQuerySearch;
    }

    const amaliaFunctions = AmaliaFunction.getAllFunctions();
    const currentFunction = amaliaFunctions[parserResult.function as keyof typeof amaliaFunctions] as
      | AmaliaFunction
      | undefined;

    const currentFunctionParam = currentFunction?.params[parserResult.argIndex];

    return currentFunctionParam
      ? tokensFilteredByQuerySearch.filter((item: FormulaEditorToken) => {
          // If there is a filter selected, we want to filter the items based on the objectDefinitionName.
          const filterItemToken = items.find((item) => item.formula === parserResult.selectedFilterMachineName);
          return (
            isItemValidForFunctionArgument(item, currentFunctionParam) &&
            isItemValidForSelectedFilterMachineName(item, filterItemToken?.objectDefinitionName)
          );
        })
      : tokensFilteredByQuerySearch;
  }, [formula, items, query]);
