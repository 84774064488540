import { type IntlShape } from 'react-intl';
import * as Yup from 'yup';

import {
  DateTransform,
  FormulaBuilderBlockType,
  FormulaBuilderDateOperatorNoArgs,
  FormulaBuilderDateOperatorOneArg,
  FormulaBuilderDateOperatorTwoArgs,
  type FormulaBuilderFunctionBlockDate,
  FormulaBuilderFunctionCategory,
  type ValueOrAttributeDate,
  ValueOrAttributeType,
} from '@amalia/amalia-lang/formula/types';
import { TokenType } from '@amalia/amalia-lang/tokens/types';
import { isEnum } from '@amalia/ext/typescript';

import { yupFormulaBuilderFunctionArgsAttributes } from './yupValidatorFormulaBuilderFunctionString';

const yupFormulaBuilderFunctionDateArgs = (valueOrAttribute: ValueOrAttributeDate) => {
  const { type } = valueOrAttribute;
  switch (type) {
    case ValueOrAttributeType.VALUE:
      return Yup.object()
        .shape({
          type: Yup.string().oneOf([ValueOrAttributeType.VALUE]).required(),
          value: Yup.number().required().notOneOf([0]),
        })
        .required();
    case ValueOrAttributeType.ATTRIBUTE:
      return Yup.object()
        .shape({
          fieldType: Yup.string().oneOf(Object.values(TokenType)).required(),
          options: Yup.object()
            .shape({
              transform: Yup.string().oneOf(Object.values(DateTransform)).nullable(),
              value: Yup.number().when('transform', ([transform], schema) =>
                !transform ? schema.nullable() : schema.required(),
              ),
            })
            .required(),
          type: Yup.string().oneOf([ValueOrAttributeType.ATTRIBUTE]).required(),
          ...('fieldType' in valueOrAttribute && {
            ...yupFormulaBuilderFunctionArgsAttributes(valueOrAttribute),
          }),
        })
        .required();
    default:
      throw new Error(`Invalid type ${type}`);
  }
};

const yupFormulaBuilderFunctionDateNoArgs = () =>
  Yup.object().shape({
    args: Yup.array()
      .of(Yup.lazy((arg: ValueOrAttributeDate) => yupFormulaBuilderFunctionDateArgs(arg)))
      .length(1),
    category: Yup.string().oneOf([FormulaBuilderFunctionCategory.DATE]).required(),
    id: Yup.string().required(),
    isDraft: Yup.boolean().required().oneOf([false]),
    operator: Yup.string().oneOf(Object.values(FormulaBuilderDateOperatorNoArgs)).required(),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FUNCTION]).required(),
  });

const yupFormulaBuilderFunctionDateOneArg = () =>
  Yup.object().shape({
    args: Yup.array()
      .of(Yup.lazy((arg: ValueOrAttributeDate) => yupFormulaBuilderFunctionDateArgs(arg)))
      .length(2),
    category: Yup.string().oneOf([FormulaBuilderFunctionCategory.DATE]).required(),
    id: Yup.string().required(),
    isDraft: Yup.boolean().required().oneOf([false]),
    operator: Yup.string().oneOf(Object.values(FormulaBuilderDateOperatorOneArg)).required(),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FUNCTION]).required(),
  });

const yupFormulaBuilderFunctionDateTwoArgs = () =>
  Yup.object().shape({
    args: Yup.array()
      .of(Yup.lazy((arg: ValueOrAttributeDate) => yupFormulaBuilderFunctionDateArgs(arg)))
      .length(3),
    category: Yup.string().oneOf([FormulaBuilderFunctionCategory.DATE]).required(),
    id: Yup.string().required(),
    isDraft: Yup.boolean().required().oneOf([false]),
    operator: Yup.string().oneOf(Object.values(FormulaBuilderDateOperatorTwoArgs)).required(),
    type: Yup.string().oneOf([FormulaBuilderBlockType.FUNCTION]).required(),
  });

/** Date function formulaBuilder validator */
export const yupFormulaBuilderFunctionDate = (dateBlock: FormulaBuilderFunctionBlockDate, intl: IntlShape) => {
  switch (true) {
    // null is needed here because as long as we don't have an operator
    // we don't know which validator to use
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- it's a validator, it validates.
    case dateBlock.operator === null:
      return Yup.object().shape({
        isDraft: Yup.boolean().required().oneOf([false]),
      });
    case isEnum(dateBlock.operator, FormulaBuilderDateOperatorNoArgs):
      return yupFormulaBuilderFunctionDateNoArgs();
    case isEnum(dateBlock.operator, FormulaBuilderDateOperatorOneArg):
      return yupFormulaBuilderFunctionDateOneArg();
    case isEnum(dateBlock.operator, FormulaBuilderDateOperatorTwoArgs):
      return yupFormulaBuilderFunctionDateTwoArgs();
    default:
      throw new Error(intl.formatMessage({ defaultMessage: 'Date function block operator not handled for now' }));
  }
};
