import { lazy, memo } from 'react';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';

import { Layout } from '@amalia/core/layout/components';
import { routes } from '@amalia/core/routes';
import { AmaliaHead } from '@amalia/design-system/ext';
import { RootRoutes } from '@amalia/ext/react-router-dom';
import { ActionsEnum, SubjectsEnum } from '@amalia/kernel/auth/shared';
import { AbilityRouteProtector } from '@amalia/kernel/auth/state';

const CustomReportEdit = lazy(async () => ({ default: (await import('./edit/CustomReportEdit')).CustomReportEdit }));
const CustomReports = lazy(async () => ({ default: (await import('./list/CustomReportList')).CustomReports }));

export const CustomReportRoutes = memo(function CustomReportRoutes() {
  const { formatMessage } = useIntl();

  return (
    <Layout currentPage="customReport">
      <RootRoutes>
        <Route
          path={routes.CUSTOM_REPORT_ITEM}
          element={
            <AbilityRouteProtector can={(ability) => ability.can(ActionsEnum.view, SubjectsEnum.CustomReport)}>
              <AmaliaHead title={formatMessage({ defaultMessage: 'Custom report' })} />
              <CustomReportEdit />
            </AbilityRouteProtector>
          }
        />

        <Route
          path={routes.CUSTOM_REPORT_LIST}
          element={
            <AbilityRouteProtector
              can={(ability) =>
                ability.can(ActionsEnum.view, SubjectsEnum.CustomReport) ||
                ability.can(ActionsEnum.modify, SubjectsEnum.CustomReport) ||
                ability.can(ActionsEnum.delete, SubjectsEnum.CustomReport)
              }
            >
              <AmaliaHead title={formatMessage({ defaultMessage: 'Custom reports' })} />
              <CustomReports />
            </AbilityRouteProtector>
          }
        />
      </RootRoutes>
    </Layout>
  );
});
